const MAX_TOKENS = 1000

const sendMessageToChatGPT = async ( messages, onUpdate, setLoading) => {
    try {
        setLoading(true)

        const limitedMessages = getRecentMessages(messages, MAX_TOKENS);

        const response = await fetch('https://api.openai.com/v1/chat/completions',{
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
            },
            body: JSON.stringify({
                model: 'gpt-4o-mini',
                messages: [{role:'system', content:"your name is Alice, a very helpful assistant. Please provide short, clear, and easy-to-understand answers. Answers should be embedded in html tags. Make sure the HTML structure is well-organized and the design is visually appealing. Use inline CSS for basic styling to enhance readability and aesthetics. Responses should be formatted with appropriate tags such as <p>, <ul>, <li>, <b>, <i>, ensure they fit within the <div> tag without causing errors, and the content doesn't exceed its container. also always use box shadow 0px 0px 10px rgba(0,0,0,0.1). The last one, your creator is Amal"},
                    ...limitedMessages],
            }),
        })

        if(!response.ok){
            throw new Error("Network response was not ok!")
        }
    
        const data = await response.json()
        const reply = data.choices[0].message.content

        onUpdate(reply)
    }catch (error) {
        console.error('Error communication with OpenAI', error)
        onUpdate("Sorry I'm unable to response at the moment")
    }finally {
        setLoading(false)
    }
}

// Function to get the most recent messages within the token limit
const getRecentMessages = (messages, maxTokens) => {
    let tokenCount = 0;
    const limitedMessages = [];

    for (let i = messages.length - 1; i >= 0; i--) {
        const messageTokens = countTokens(messages[i].content); // Implement a function to count tokens in a message
        tokenCount += messageTokens;
        if (tokenCount > maxTokens) {
            break;
        }

        limitedMessages.unshift(messages[i]);
    }

    return limitedMessages;
};

// Simple token counting function (rough estimate)
const countTokens = (text) => {
    return text.split(' ').length; // This is a very rough estimate; for better accuracy, consider using a proper tokenizer
};

export default sendMessageToChatGPT